/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBadge from "components/SoftBadge";
import { useTranslation } from "react-i18next";

function PricelistTabMob({ pricelist }) {
    const { t, i18n } = useTranslation();

    const options = { maximumFractionDigits: 2 }

    return (
        <SoftBox>
            <Card>
                <SoftBox p={0}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={12} lg={12}>
                            {pricelist.map((item, index) =>
                                <div key={index} style={{ margin: "8px 0" }}><Grid item xs={12} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Card>
                                                    <SoftBox pt={0.5} pb={1} px={1}>
                                                        <SoftTypography variant="h6">{t('tabs.tab_price_desc')}</SoftTypography>
                                                        <SoftBox mt={1} mb={2} lineHeight={0}>
                                                            <SoftTypography variant="button" fontWeight="regular" color="text">
                                                                {item.description}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox
                                                            bgColor="grey-100"
                                                            justifyContent="space-between"
                                                            alignItems="left"
                                                            borderRadius="lg"
                                                            p={1}
                                                            >
                                                            <SoftTypography variant="button" fontWeight="regular" color="text" style={{ width:"100%"}}>
                                                                <p>{t('tabs.tab_price_reg')} <b>{Intl.NumberFormat("en-US", options).format(item.price) + " " + item.currency} </b></p>
                                                            </SoftTypography>
                                                            <SoftTypography variant="button" fontWeight="regular" color="text" style={{ width:"100%"}}>
                                                                <p>{t('tabs.tab_price_disc')} <span style={{ color:"#fb9b50", fontSize:"20px"}} >{item.discount + "%"}</span></p>
                                                            </SoftTypography><br></br>
                                                            <SoftTypography variant="button" align="center" fontWeight="regular" color="text" style={{ width:"100%"}}>
                                                                <p> <SoftTypography color="secondary" variant="body2" textTransform="uppercase">{t('tabs.tab_price_new')}: <SoftBadge container variant="contained" color="secondary" badgeContent={Intl.NumberFormat("en-US", options).format(item.discountedPrice) + " " + item.currency} /></SoftTypography></p>
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default PricelistTabMob;

