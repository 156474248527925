/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import { Divider, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import WorkingHours from '@mui/icons-material/AlarmOn';
import {useTranslation} from "react-i18next";
import typography from "assets/theme/base/typography";

function ProgramsAndScheduleTab({ skillLevels, sports, ageCategories, gender, groupActivityPayload, sportFacilityPayload, personalCoachPayload, isMobile }) {
    const data = location.state;
    const { t, i18n } = useTranslation();
    return (
        <SoftBox>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6.5}>
                    <SoftTypography variant="h6" mb={2}>
                    {t('tabs.tab_activity_preferences')}
                    </SoftTypography>
                    <Grid container>
                        <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                            <SoftTypography variant="body2">{t('tabs.tab_readiness')}:&nbsp;</SoftTypography>
                            <SoftBox >
                                {skillLevels?.length > 0 ? (skillLevels?.map((item, index) => (
                                    <SoftBadge size="xs" style={{ margin: '0 0 0 5px' }} key={"skillLevel-" + index} variant="contained" color="secondary" badgeContent={item.label} />
                                ))) : "/"}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                            <SoftTypography variant="body2">{t('tabs.tab_available_sports')}:&nbsp;</SoftTypography>
                            <SoftBox>
                                {sports?.length > 0 ? (sports?.map((item, index) => (
                                    <SoftBadge style={{ margin: '0 0 0 5px' }} size="xs" key={"sport-" + index} variant="contained" color="primary" badgeContent={item.label} />
                                ))) : "/"}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                            <SoftTypography variant="body2">{t('tabs.tab_age_border')}:&nbsp;</SoftTypography>
                            <SoftBox>
                                {ageCategories?.length > 0 ? (ageCategories?.map((item, index) => (
                                    <SoftBadge size="xs" style={{ margin: '0 0 0 5px' }} key={"ageCategory-" + index} variant="contained" color="secondary" badgeContent={item.label} />
                                ))) : "/"}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                            <SoftTypography variant="body2"> {t('tabs.tab_gender')}:&nbsp; </SoftTypography>
                            <SoftBox>
                                {gender ? <SoftBadge size="xs" style={{ margin: '0 0 0 5px' }} variant="contained" color="primary" badgeContent={gender} />
                                    : "/"}
                            </SoftBox>
                        </Grid>
                        {groupActivityPayload?.numberOfFreeTrainings > 0 && (
                            <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                                <SoftTypography variant="body2">{t('tabs.tab_free_training')}:&nbsp; </SoftTypography>
                                <SoftBox>
                                    <SoftBadge size="xs" style={{ margin: '0 0 0 5px' }} variant="contained" color="secondary" badgeContent={groupActivityPayload?.numberOfFreeTrainings} />
                                </SoftBox>
                            </Grid>
                        )}
                        {personalCoachPayload?.numberOfFreeTrainings > 0 && (
                            <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                                <SoftTypography variant="body2">{t('tabs.tab_free_training')}:&nbsp; </SoftTypography>
                                <SoftBox>
                                    <SoftBadge size="xs" style={{ margin: '0 0 0 5px' }} variant="contained" color="secondary" badgeContent={personalCoachPayload?.numberOfFreeTrainings} />
                                </SoftBox>
                            </Grid>
                        )}
                        {sportFacilityPayload?.numberOfFreeTrainings > 0 && (
                            <Grid item xs={12} lg={12} md={12} display="flex" alignItems="center">
                                <SoftTypography variant="body2">{t('tabs.tab_free_training')}:&nbsp; </SoftTypography>
                                <SoftBox>
                                    <SoftBadge size="xs" style={{ margin: '0 0 0 5px' }} variant="contained" color="secondary" badgeContent={sportFacilityPayload?.numberOfFreeTrainings} />
                                </SoftBox>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    {sportFacilityPayload?.workingHours?.length > 0 && (
                        <SoftBox>
                            {isMobile && (
                                <Divider style={{marginTop: 0}}/>
                            )}
                            <SoftTypography variant="h6" mb={2}>
                            {t('tabs.tab_working_hours')}
                            </SoftTypography>
                            {sportFacilityPayload?.workingHours.map((item, i) => (
                                <SoftBox display="flex" alignItems="center" mb={1} key={"workingHourItem-" + i}><WorkingHours color="primary" size="medium" /><SoftTypography variant="body2" textTransform="capitalize" ml={1}>{item.day}: {item.startTime} - {item.endTime}</SoftTypography></SoftBox>
                            ))}
                        </SoftBox>
                    )}
                    {groupActivityPayload?.schedule?.length > 0 && (
                        <SoftBox>
                            {isMobile && (
                                <Divider style={{marginTop: 0}}/>
                            )}
                            <SoftTypography variant="h6" mb={2}>
                            {t('tabs.tab_time_programs')}
                            </SoftTypography>
                            {groupActivityPayload?.schedule.map((item, i) => (
                                <SoftBox display="flex" alignItems="center" mb={1} key={"workingHourItem-" + i}><WorkingHours color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.description}: {item.startTime} - {item.endTime}</SoftTypography></SoftBox>
                            ))}
                        </SoftBox>
                    )}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {groupActivityPayload?.aboutPrograms && (
                        <SoftBox mt={isMobile ? 0 : 2}>
                            {isMobile && (
                                <Divider style={{marginTop: 0}}/>
                            )}
                            <SoftTypography variant="h6" mb={2}>
                            {t('tabs.tab_more_about_program')}
                            </SoftTypography>
                            <SoftTypography variant="body2" textAlign="justify">
                                <div className="post__content" style={typography.body2}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: groupActivityPayload?.aboutPrograms }}
                                    />
                                    <style>
                                        {`
                                        .post__content ul, .post__content ol {
                                            margin-left: 20px;
                                            padding-left: 20px;
                                        }
                                        `}
                                    </style>
                                </div>
                            </SoftTypography>
                        </SoftBox>
                    )}
                </Grid>
            </Grid>
        </SoftBox >
    )
}

export default ProgramsAndScheduleTab;

