import axios from "axios";
import MessagesTexts from "helpers/messages/messages-texts";
import BackRequests from "./back-requests.enum";

import unknown from "assets/images/business-item-unknown.png";
import RoutesNames from "helpers/enums/routes-map.enum";
import { Buffer } from 'buffer';

// const BACK_SERVICE_URL = process.env.REACT_APP_BACK_SERVICE_URL;
const BACK_SERVICE_URL = "https://moveon.fit/api/";

async function CreatePostRequest(routeName, body) {
  try {
    let headers = {};
    if (body) {
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    }
    return await axios.post(BACK_SERVICE_URL + routeName, body, { headers, withCredentials: true });
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = RoutesNames.SIGN_IN;
    }
    throw error;
  }
}

async function CreateMultipartPostRequest(routeName, body) {
  try {
    return await axios.post(BACK_SERVICE_URL + routeName, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
      }, withCredentials: true
    });
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = RoutesNames.SIGN_IN;
    }
    throw error;
  }
}

async function CreateGetRequest(routeName, config = {}) {
  try {
    return await axios.get(BACK_SERVICE_URL + routeName,
      {
        ...config,
        withCredentials: true
      });
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = RoutesNames.SIGN_IN;
    }
    throw error;
  }

}

async function CreateDeleteRequest(routeName) {
  try {
    return await axios.delete(BACK_SERVICE_URL + routeName, { withCredentials: true });
  } catch (error) {
    if (error.response?.status === 401) {
      window.location.href = RoutesNames.SIGN_IN;
    }
    throw error;
  }
}

function GetBackUrl() {
  return BACK_SERVICE_URL;
}

function checkIfResponseIsForClient(error) {
  return error.response?.data?.responseCode > 100 && error.response?.data?.responseCode < 200
}

function GetErrorMessage(error) {
  if (checkIfResponseIsForClient(error)) {
    return error.response.data.message;
  } else {
    return MessagesTexts.UNEXPECTED_ERROR;
  }
}

const GetImageUrl = (imageId) => {
  if (imageId) {
    return GetBackUrl() + BackRequests.GET_MEDIA + imageId;
  }
  return unknown;
}

const GetUploadImageUrl = () => {
  return GetBackUrl() + BackRequests.UPLOAD_MEDIA;
}

const UploadImage = async (image) => {
  const imageBase64 = image.split(',')[1];
  const body = new FormData();
  const buff = Buffer.from(imageBase64, "base64");
  body.append('file', new Blob([buff], { type: `image/jpeg` }));
  const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
  return response?.data?.data?.fileName;
}

export { CreatePostRequest, CreateMultipartPostRequest, CreateGetRequest, CreateDeleteRequest, GetErrorMessage, GetBackUrl, GetImageUrl, GetUploadImageUrl, UploadImage };
