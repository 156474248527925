import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Default styles
import "./imageGalleryStyle.css"; // Your custom styles

const MyGallery = ({ items }) => {
    return (
      <ImageGallery
        items={items.map((img) => ({
          original: img,
          thumbnail: img,
        }))}
        showFullscreenButton={true}
        showThumbnails={true}
        autoPlay={false}
        showPlayButton={false}
        showBullets={true}
        lazyLoad={true}
      />
    );
  };
  
  export default MyGallery;