/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
import MyGallery from "layouts/pages/business/new-business/components/Gallery";

function MediaTab({ galleryImages, isMobile }) {
    return (
        <SoftBox>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12} xl={12} sx={!isMobile && {display:"flex", justifyContent:"center"}}>
                    <SoftBox sx={!isMobile && {width: "50%"}}>
                        <MyGallery
                            items={galleryImages}
                            showFullscreenButton={true}
                            showThumbnails={true}
                            autoPlay={false}
                            showPlayButton={false}
                            showBullets={true}
                            lazyLoad={true}
                        />
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default MediaTab;

