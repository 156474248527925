/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import breakpoints from "assets/theme/base/breakpoints";
import icon_instagram from "assets/images/icons/icon_instagram.png";
import iconX from "assets/images/icons/icon_x.png";
import icon_facebook from "assets/images/icons/icon_facebook.png";
import { useTranslation } from "react-i18next";
import HeaderMenu from "components/common/HeaderMenu";
import BusinessInfoPreviewInCard from "../BusinessInfoPreviewInCard";

function PageHeader({ businessName, businessId, businessRating, businessRatingCount, businessCoverImage, businessMainImage, isAdmin, businessType, coachBirth, sports, socialAccountsFacebook, socialAccountsInstagram, socialAccountsX, addresses }) {
  const { t, i18n } = useTranslation();
  const [mobileStyle, setMobileStyle] = useState(false);

  useEffect(() => {
    function handleElementsAlignment() {
      return window.innerWidth <= breakpoints.values.sm
        ? setMobileStyle(true)
        : setMobileStyle(false);
    }

    window.addEventListener("resize", handleElementsAlignment);

    handleElementsAlignment();

    return () => window.removeEventListener("resize", handleElementsAlignment);
  }, [mobileStyle]);

  const isOnlySocialMedia = () => {
    return [socialAccountsFacebook, socialAccountsInstagram, socialAccountsX].filter(Boolean).length === 1;
  }

  return (
    <SoftBox position="relative" >
      {!isAdmin &&
        <HeaderMenu />
      }
      <SoftBox
        width="100%"
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        // borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `url(${businessCoverImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
      </SoftBox>
      <Card sx={{
        width: "85%",
        backdropFilter: `saturate(200%) blur(30px)`,
        backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
        boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
        position: "relative",
        mt: -8,
        mx: 3,
      }} >
        <SoftBox p={1} display="flex" sx={{ height: "100%" }}>
          <Grid container spacing={1} >
            <Grid item xs={12} md={4} lg={3} xl={1.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SoftBox display="flex" 
                width={mobileStyle ? "50%" : "auto"}
              >
                <SoftBox
                  component="img"
                  src={businessMainImage}
                  alt="message-image"
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  style={{ objectFit: "cover" }}
                />
              </SoftBox>
            </Grid>
            {
              businessName && (
                <Grid item xs={12} md={4} lg={6} xl={8.5} display="flex" flexDirection="column"
                  alignItems={mobileStyle ? "center" : "left"}>
                  <BusinessInfoPreviewInCard
                    title={businessName}
                    type={businessType}
                    addresses={addresses}
                    sports={sports?.map((sport) => sport.label)}
                    rating={businessRating}
                    ratingCount={businessRatingCount}
                    eventId={businessId}
                    hideAction
                    biggerFont
                    mobileStyle={mobileStyle}
                  />
                </Grid>
              )
            }
            <Grid item xs={12} md={4} lg={3} xl={2} display="flex" flexDirection="column" justifyContent="center" 
              alignItems={mobileStyle ? "center" : "end"}
              >
              <SoftBox display="flex" justifyContent="center" px={1}
              mr={mobileStyle ? 0 : 5}
              mt={mobileStyle ? 1 : 0}>
              {socialAccountsInstagram &&
                <a href={socialAccountsInstagram} target="_blank">
                  <SoftBox
                    component="img"
                    src={icon_instagram}
                    alt="Cover"
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              }
              {socialAccountsFacebook &&
                <a href={socialAccountsFacebook} target="_blank">
                  <SoftBox
                    component="img"
                    src={icon_facebook}
                    alt="Cover"
                    style={{ width: "35px", height: "35px"}}
                    ml={isOnlySocialMedia() ? 0 : 1.5}
                  />
                </a>}
              {socialAccountsX &&
                <a href={socialAccountsX} target="_blank">
                  <SoftBox
                    component="img"
                    src={iconX}
                    alt="Cover"
                    style={{ width: "35px", height: "35px"}}
                    ml={isOnlySocialMedia() ? 0 : 1.5}
                  />
                </a>}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </SoftBox>
  );
}

export default PageHeader;
