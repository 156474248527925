/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Formik, Form } from "formik";
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React components
import FormField from "components/common/FormField";
import SoftSelect from "components/SoftSelect";
import SoftBox from "components/SoftBox";

// Form schemas
import useValidations from "layouts/pages/home/schemas/validations";
import useFormFields from "layouts/pages/home/schemas/form";
import useInitialValues from "layouts/pages/home/schemas/initialValues";
import FormNames from "layouts/pages/home/schemas/form-names.enum";

import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BadgeIcon from '@mui/icons-material/Badge';
import { useNavigate } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";

function SimpleFilters({ handleSearch }) {
    const { t, i18n } = useTranslation();
    const [refreshKey, setRefreshKey] = useState(0);
    const navigate = useNavigate();

    const { initial, getInitialValuesForSearch } = useInitialValues();

    const formValidation = useValidations()[FormNames.SIMPLE_SEARCH];
    const formFields = useFormFields(t)[FormNames.SIMPLE_SEARCH];
    const initialValues = initial[FormNames.SIMPLE_SEARCH];

    const handleSubmit = async (values) => {
        try {
            console.log("SIMPLE SEARCH HANDLE SUBMIT")
            console.log(values)

            const stateData = {
                ...(values[formFields.businessNameSimpleSearch.name] && { name: values[formFields.businessNameSimpleSearch.name] }),
                ...(values[formFields.locationSimpleSearch.name] && { location: values[formFields.locationSimpleSearch.name] }),
            }
            console.log(stateData)

            if (values[formFields.categorySimpleSearch.name]) {
                switch (values[formFields.categorySimpleSearch.name].value) {
                    case BusinessItemTypes.EVENT:
                        navigate(RoutesNames.EVENTS, { state: stateData });
                        break;
                    case BusinessItemTypes.PERSONAL_COACH:
                        navigate(RoutesNames.PERSONAL_COACHES, { state: stateData });
                        break;
                    case BusinessItemTypes.SPORT_FACILITY:
                        navigate(RoutesNames.SPORT_FACILITIES, { state: stateData });
                        break;
                    case BusinessItemTypes.GROUP_ACTIVITY:
                        navigate(RoutesNames.GROUP_ACTIVITIES, { state: stateData });
                        break;
                    default:
                        navigate(RoutesNames.SEARCH, { state: stateData });
                        break;
                }
            } else {
                navigate(RoutesNames.SEARCH, { state: stateData });
            }
        } catch (error) {
            console.log(error)
            // TODO
            // openErrorSB(GetErrorMessage(error));
        }
    };

    return (
        <Card sx={{
            width:"80%",
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.7),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "relative",
            mt: -4,
        }}>
            <SoftBox p={2}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={formValidation}
                    onSubmit={handleSubmit}
                    key={refreshKey}
                >
                    {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
                        <Form id={FormNames.SIMPLE_SEARCH} autoComplete="off">
                            <SoftBox>
                                <SoftBox px={3}>
                                    <Grid container align="left" columnSpacing={3}>
                                        <Grid item xs={12} sm={12} lg={3}></Grid>
                                        <Grid item xs={12} sm={12} lg={2}>
                                            <SoftBox mt={-4}>
                                                <FormField
                                                    type={formFields.businessNameSimpleSearch.type}
                                                    label=""
                                                    placeholder={formFields.businessNameSimpleSearch.label}
                                                    name={formFields.businessNameSimpleSearch.name}
                                                    value={values.businessNameSimpleSearch}
                                                    icon={{ component: <BadgeIcon color="primary" />, direction: "left" }}

                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={2}>
                                            <SoftBox mt={-4}>
                                                <FormField
                                                    type={formFields.locationSimpleSearch.type}
                                                    label=""
                                                    placeholder={formFields.locationSimpleSearch.label}
                                                    name={formFields.locationSimpleSearch.name}
                                                    value={values.locationSimpleSearch}
                                                    icon={{ component: <LocationOnIcon color="primary" />, direction: "left" }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={2}>
                                            {/* <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                                    {t(formFields.categorySimpleSearch.label)}
                                                </SoftTypography>
                                            </SoftBox> */}
                                            <SoftBox >
                                                <SoftSelect
                                                    menuPortalTarget={document.querySelector('body')}
                                                    displayEmpty
                                                    name={formFields.categorySimpleSearch.name}
                                                    placeholder={formFields.categorySimpleSearch.placeholder}
                                                    options={formFields.categorySimpleSearch.options}
                                                    onChange={(event) => {
                                                        setFieldValue(formFields.categorySimpleSearch.name, event);
                                                    }}
                                                    isClearable
                                                    isSearchable={false}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}></Grid>
                                    </Grid>
                                </SoftBox>
                                <SoftBox mt={1} display="flex" justifyContent="center">
                                    <SoftButton
                                        variant="contained" color="secondary"
                                        type="submit"
                                    >{t('searchForm.btnSearch')}</SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </Form>
                    )}
                </Formik>
            </SoftBox>
        </Card>
    );
}

export default SimpleFilters;
